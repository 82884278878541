<template>
  <div id="promoter" class="select-main">
    <div class="div-main">
      <loading :loading="loading" :text="loadingText"></loading>
      <el-form id="formSearch" ref="form" label-width="100px" style="border-bottom: 1px solid #f6f6f6; margin-bottom: 5px; margin-top: 5px;">
        <el-form-item label="订单号" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-input type="number"  v-model="orderNo" placeholder="请输入" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="手机号" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-input type="number" v-model="username" placeholder="请输入" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="分销员" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-input type="number" v-model="sharerId" placeholder="请输入ID" style="width: 200px"></el-input>
        </el-form-item>
<!--        <el-form-item label="订单状态" style="width: 300px; margin-bottom:10px;display: inline-block">-->
<!--          <el-select v-model="status" placeholder="请选择">-->
<!--            <el-option-->
<!--                v-for="status in statuses"-->
<!--                :key="status.id"-->
<!--                :label="status.name"-->
<!--                :value="status.id"-->
<!--            >-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item label="订单类型" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-select v-model="type" placeholder="请选择">
            <el-option :key="orderTypeEnum.PU_TONG"       label="普通订单"    :value="orderTypeEnum.PU_TONG"></el-option>
            <el-option :key="orderTypeEnum.PIN_TUAN"      label="拼团订单"    :value="orderTypeEnum.PIN_TUAN"></el-option>
            <el-option :key="orderTypeEnum.JI_FEN"        label="积分订单"    :value="orderTypeEnum.JI_FEN"></el-option>
            <el-option :key="orderTypeEnum.DISTRIBUTION"  label="分销订单"    :value="orderTypeEnum.DISTRIBUTION"></el-option>
            <el-option :key="orderTypeEnum.UNIVERSITY"    label="院校调剂包"  :value="orderTypeEnum.UNIVERSITY"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="购买商品" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-input @keyup.native="productIdOnChange()" v-on:blur="productIdOnBlur()" type="number" v-model="productId" placeholder="请输入ID" style="width: 200px"></el-input>
          <span  class="product-tip" v-show="productName == '' ? false : true">{{ productName }}</span>
        </el-form-item>
        <el-form-item label="是否免费" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-select v-model="isFree"  placeholder="请选择">
            <el-option :key="true"  label="是" :value="true" ></el-option>
            <el-option :key="false"  label="否" :value="false" ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属分校" style="width: 300px; margin-bottom:10px;display: inline-block">
          <branch-select ref="branchSelect"></branch-select>
        </el-form-item>
        <el-form-item label="订单日期" style="width: 480px; margin-bottom:10px;display: inline-block">
          <el-date-picker
              v-model="dateTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
          >
          </el-date-picker>
        </el-form-item>
        <div style="display:inline-block;margin-left: 60px">
          <el-button type="primary" @click="getData">统计</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </div>
      </el-form>
      <div id="divReport" v-show="tableShow">
        <table>
          <tr><td>微信订单总数：</td><td>{{report.wxNum}}单</td></tr>
          <tr><td>支付宝订单总数：</td><td>{{report.alipayNum}}单</td></tr>
          <tr><td>微信收费总额：</td><td>{{report.wxAmount}}元</td></tr>
          <tr><td>支付宝收费总额：</td><td>{{report.alipayAmount}}元</td></tr>
        </table>
      </div>
    </div>
    <edit-dialog ref="editDialog"></edit-dialog>
  </div>
</template>

<script>
import Loading from "../../components/Loading";
import {UrlEnum,getLocalProjectId,OrderStatusEnum,OrderTypeEnum} from "../../../public/js/common-vue";
import Pagination from "../../components/Pagination";
import EditDialog from "../../components/EditDialog";
import BranchSelect from "../../components/BranchSelect";
export default {
  name: "OrderList",
  data() {
    return {
      loading: false,
      loadingText: "加载中",
      tableShow: false,
      orderNo: '',
      username: '',
      total: 0,
      page: 1,
      size: 20,
      status:'',
      productId:'',
      productName:'',
      isFree:'',
      dateTime: '',
      isShowOpenBtn:false,
      isShowCloseBtn:false,
      orderStatusEnum:OrderStatusEnum,
      orderTypeEnum:OrderTypeEnum,
      statuses: [],
      promoters: [],
      orders:[],
      type:'',
      sharerId:'',
      report:{
        totalNum:'',
        totalAmount:''
      }
    }
  },
  methods:{
    productIdOnChange(){
      console.log('onChange')
      if(this.productId == ''){
        this.productName = '';
      }
      else{
        this.getCourseById(this.productId);
      }
    },
    productIdOnBlur(){
      console.log('onBlur');
      this.productName='';
    },

    makeUrl() {
      let url = UrlEnum.ORDER_REPORT;
      url += "?page="+this.page+"&size="+this.size+"&projectId="+getLocalProjectId();
      if(this.orderNo != ''){
        url += "&orderNo="+this.orderNo;
      }
      if(this.username != ''){
        url += "&username="+this.username;
      }
      // if(this.status != ''){
      //   url += "&status="+this.status;
      // }
      if(this.dateTime != ''){
        let startTime = this.moment(this.dateTime[0]).format("YYYY-MM-DD HH:mm:ss");
        let endTime = this.moment(this.dateTime[1]).format("YYYY-MM-DD HH:mm:ss");
        url += "&startTime="+startTime+"&endTime="+endTime;
      }
      if(this.productId != ''){
        url += "&productId="+this.productId;
      }
      if(this.isFree != ''){
        url += "&isFree="+this.isFree;
      }
      if(this.type != ''){
        url += "&type="+this.type;
      }
      if(this.sharerId != ''){
        url += "&type="+OrderTypeEnum.DISTRIBUTION+"&sharerId="+this.sharerId;
      }
      let branchId = this.$refs.branchSelect.getBranchId();
      if(branchId != ''){
        url += "&branchId="+ branchId;
      }
      return url;
    },
    getData(){
      this.loading=true;
      const url = this.makeUrl();
      this.$http({
        method: "get",
        url: url,
        data: '',
      })
      .then((res) => {
        this.loading = false;
        this.report = res.data;
        this.tableShow = true;
      })
      .catch((res) => {
        this.loading = false;
        if (res.response != null) {
          const data = res.response.data;
          this.showMsgError(data.message);
        }
        this.tableShow=true;
      })
    },
    getStatus(){
      this.loading = true;
      const url = UrlEnum.ORDER_STATUS;
      this.$http({
        method: "get",
        url: url,
        data: '',
      })
      .then((res) => {
        this.loading = false;
        this.statuses =  res.data.list;
      })
      .catch((res) => {
        this.loading = false;
        if (res.response != null) {
          const data = res.response.data;
          this.showMsgError(data.message);
        }
        this.tableShow = true;
      })
    },
    reset(){
      this.dateTime = '';
      this.isFree = '';
      this.productName='';
      this.productId='';
      this.username = '';
      this.orderNo = '';
      this.type = '';
      this.sharerId = '';
      this.$refs.branchSelect.setBranchId('');
      this.getData();
    },
    getCourseById(courseId){
      const url = UrlEnum.COURSE + "/" + courseId;
      this.$http({
        method: "get",
        url: url,
        data: '',
      })
      .then((res) => {
        if(res.data.name != undefined){
          this.productName = res.data.name;
        }
      })
      .catch((res) => {
        if (res.response != null) {
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      })
    }
  },
  components:{Loading,UrlEnum,Pagination,EditDialog,BranchSelect},
  mounted() {
    this.$refs.branchSelect.initData(null);
    this.getStatus();
    const that = this;
    window.addEventListener('message', function (e) {
      if(e.data.key == "refresh"){
        that.$refs.editDialog.hide();
        that.getData();
      }
    }, false)
  }
}
</script>

<style>
.div-main{
  background: white;
  padding:10px 0px;
  height: 100%;
  overflow: auto;
  margin:15px;
}
.select-main{
  height: 100%;
}
.table-header th {
  background-color: #f6f6f6 !important;
}
.el-table td {
  padding: 6px 0 !important;
}
#divReport{
  margin:30px;
}
#divReport div{
  height: 30px;
  line-height: 30px;
}
.opt{
  height: 40px;
}
.product-tip{
  position: absolute;
  width: 178px;
  z-index: 1;
  background: white;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  padding: 10px;
  color: #666;
  margin-top: 40px;
  margin-left: -200px;
  line-height: 25px;
}
</style>
